import React from "react";
import "./MockupAnimation.css";
import mockup1 from "./assets/mockup1.png";
import mockup2 from "./assets/mockup2.png";
import mockup3 from "./assets/mockup3.png";
import mockup4 from "./assets/mockup4.png";
import mockup5 from "./assets/mockup5.png";

const MockupAnimation = () => {
  return (
    <div className="mockup-animation">
      <div className="mockup-container">
        <img src={mockup1} alt="" className="mockup delay1" />
        <img src={mockup2} alt="" className="mockup delay2" />
        <img src={mockup3} alt="" className="mockup delay3" />
        <img src={mockup4} alt="" className="mockup delay4" />
        <img src={mockup5} alt="" className="mockup delay5" />
      </div>
    </div>
  );
};

export default MockupAnimation;
