import React, { useState } from "react";
import "./App.css";
import MockupAnimation from "./MockupAnimation";
import logo from "./assets/Asset 9.png";
import playStoreLogo from "./assets/ps.png";
import TypingEffect from "./TypingEffect";

const App = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleWeleriClick = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000); // Notifikasi akan hilang setelah 3 detik
  };

  const handleCoverageClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className={`App ${showModal ? "modal-open" : ""}`}>
      <header className="navbar">
        <img src={logo} alt="Paktuku Logo" />
      </header>
      <MockupAnimation />
      <main className="content">
        <div className="row">
          <div className="col-6 text-content">
            <h1 className="title">
              <TypingEffect text="Paktuku" speed={190} />
            </h1>
            <p className="subtitle">
              <TypingEffect
                text="Belanja Mudah Kami Antar Sampai Rumah"
                speed={50}
              />
            </p>
            <div className="app-links">
              <div className="app-link fade-in">
                <h2>Paktuku Sukorejo</h2>
                <a href="https://play.google.com/store/apps/details?id=com.paktukusukorejo.aytech">
                  <img src={playStoreLogo} alt="Get it on Google Play" />
                </a>
              </div>
              <div className="app-link fade-in">
                <h2>Paktuku Weleri</h2>
                <a href="https://play.google.com/store/apps/details?id=com.paktukuneni.aytech&pcampaignid=web_share">
                  <img src={playStoreLogo} alt="Get it on Google Play" />
                </a>
              </div>
            </div>
            <button
              className="coverage-button fade-in"
              onClick={handleCoverageClick}
            >
              Klik Untuk Melihat Cakupan Wilayah
            </button>
          </div>
          <div className="col-6">
            {/* Add additional content or images here */}
          </div>
        </div>
      </main>
      <footer>
        <div className="social-links">
          <a href="https://web.facebook.com/profile.php?id=61563434788355">
            <img
              src="https://cdn-icons-png.flaticon.com/512/5968/5968764.png"
              alt="Facebook"
              className="social-icon"
            />
          </a>
          <a href="https://www.instagram.com/paktuku.sukorejo/">
            <img
              src="https://cdn-icons-png.flaticon.com/128/15707/15707749.png"
              alt="Instagram"
              className="social-icon"
            />
          </a>
          <a href="https://www.tiktok.com/@paktuku.sukorejo">
            <img
              src="https://cdn-icons-png.flaticon.com/128/4782/4782345.png"
              alt="TikTok"
              className="social-icon"
            />
          </a>
          <a href="https://wa.me/+6288228882212">
            <img
              src="https://cdn-icons-png.flaticon.com/128/15707/15707820.png"
              alt="WhatsApp"
              className="social-icon"
            />
          </a>
        </div>
      </footer>
      {showNotification && (
        <div className="notification">
          <p>Coming Soon</p>
        </div>
      )}
      {showModal && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Paktuku Sukorejo</h2>
            <ul>
              <li>Sukorejo</li>
              <li>Patean</li>
              <li>Plantungan</li>
              <li>Pageruyung</li>
            </ul>
            <h2>Paktuku Weleri</h2>
            <ul>
              <li>Weleri</li>
              <li>Rowosari</li>
              <li>Ringinarum</li>
              <li>Gemuh</li>
              <li>Pegandon</li>
            </ul>
            <button onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
